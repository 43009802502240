<template>
  <div class="vip-audit">
    <van-swipe
      class="my-swipe"
      indicator-color="#51CCB4"
      :loop="false"
      :show-indicators="indicators"
      @change="changeSwipe"
    >
      <van-swipe-item>
        <div class="skip" @click="skipPage">跳过</div>
        <div class="img-box">
          <van-image class="clear-float" :src="require('@/assets/images/accounts/accounts-before1.png')" width="87vw" />
        </div>
        <p class="title">功能便捷</p>
        <div class="sub-title">每日收支 <span>尽在掌握</span></div>
      </van-swipe-item>
      <van-swipe-item>
        <div class="skip" @click="skipPage">跳过</div>
        <div class="img-box img-box-two">
          <van-image :src="require('@/assets/images/accounts/accounts-before2.png')" width="85.6vw" />
        </div>
        <p class="title">轻松操作</p>
        <div class="sub-title">自动同步 <span>安全放心</span></div>
      </van-swipe-item>
      <van-swipe-item>
        <div class="img-box img-box-three">
          <van-image :src="require('@/assets/images/accounts/accounts-before3.png')" width="90vw" />
        </div>
        <p class="title">省心生活</p>
        <div class="sub-title">收支趋势 <span>一目了然</span></div>

        <div class="btn" @click="skipPage">立即体验</div>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
import { Image as VanImage, Swipe, SwipeItem } from 'vant';

import { TO_MAIN } from '@/utils/Agreement';
import { showLoading, hideLoading } from '@/utils/VantUtils';
import { handlerEror } from '@/utils/Tools';

import Http from '@/utils/Http.js';

const goMain = 'cloud/ec/tally/insertOrUpdateUserMsg';

export default {
  components: {
    VanImage,
    VanSwipe: Swipe,
    VanSwipeItem: SwipeItem
  },
  data() {
    return {
      indicators: true
    };
  },
  methods: {
    skipPage() {
      showLoading();
      Http.get(goMain, {})
        .then(() => {
          hideLoading();
          window.location.href = TO_MAIN;
        })
        .catch((err) => {
          hideLoading();
          handlerEror(err);
        });
    },
    changeSwipe(val) {
      if (val == 2) {
        this.indicators = false;
        return;
      }
      this.indicators = true;
    }
  }
};
</script>

<style lang="less" scoped>
.vip-audit {
  background: #fff;
  height: 100vh;
  .my-swipe {
    height: 100%;
    .skip {
      float: right;
      margin-right: 20px;
      width: 46px;
      height: 20px;
      opacity: 1;
      border: 1px solid #d1d1d1;
      border-radius: 11px;
      font-size: 12px;
      font-weight: 500;
      text-align: center;
      color: #d1d1d1;
      line-height: 20px;
    }
    .van-swipe-item {
      margin-top: 14px;
      margin-bottom: 24px;
      .img-box {
        width: 100%;
        height: 407px;
      }
      .img-box-two {
        padding-top: 100px;
        height: 307px;
      }
      .img-box-three {
        padding-top: 98px;
        height: 307px;
      }
    }
  }
  .van-image {
    display: block;
    margin: 0 auto;
  }

  /deep/ .van-swipe__indicators {
    bottom: 82px;
    .van-swipe__indicator {
      width: 10px;
      height: 10px;
    }
    .van-swipe__indicator:not(:last-child) {
      margin-right: 12px;
    }
  }
  .title {
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    color: #51ccb4;
    margin-top: 25px;
  }
  .sub-title {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    color: #858585;
    margin-top: 15px;
    span {
      margin-left: 20px;
    }
  }
  .btn {
    margin: 60px auto 0;
    width: 210px;
    height: 52px;
    opacity: 1;
    background: #51ccb4;
    border-radius: 26px;
    font-size: 28px;
    font-weight: 500;
    text-align: center;
    color: #ffffff;
    line-height: 52px;
  }
  .clear-float {
    overflow: hidden;
  }
}
</style>
